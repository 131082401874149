import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SingleRaisedFloorQuery from "../graphql/SingleRaisedFloorQuery"
import PageHeader from "./PageHeader"
import Breadcrumbs from "../components/Breadcrumbs"
import FloorProductIntroduction from "../components/FloorProductIntroduction"
import SingleProductQuery from "../graphql/SingleProductQuery"
import PageTitle from "../components/PageTitle"
import { RaisedFloorDesc } from "../graphql/RaisedFloorSmallQueries"
import SEO from "../components/seo"

const FloorSinglePageTemplate = ({ data: { airtable } }) => {
  // console.log(airtable)
  const mainName = RaisedFloorDesc()[0].name

  // const data = airtable?.data ?? undefined
  // const path = data ?? undefined

  // const floor = SingleRaisedFloorQuery(path)
  const floor = SingleProductQuery(airtable)
  // console.log(floor)

  return (
    <Layout>
      <SEO title={floor.name} />
      {/* <PageHeader title={floor.name} /> */}
      <PageTitle title={floor.name} />
      <div className="relative z-10 max-w-screen-lg mx-auto my-10 md:shadow-xl md:p-10 md:bg-white">
        <Breadcrumbs
          // productType={floor.productType}
          productType={mainName}
          productName={floor.name}
          nameEn="raised-floor"
        />
        <FloorProductIntroduction data={floor} single />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GetSingleFloor($id: String!) {
    airtable(id: { eq: $id }) {
      data {
        nameMn: Name_mongol
        nameEn: Name_english
        Ontslog {
          data {
            nameMn: mn
            nameEn: en
            Icon {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    placeholder: NONE
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
        Type: RF_Type {
          data {
            nameMn: Name_mongol
            nameEn: Name_english
            textMn: Mongol_tailbar
            textEn: English_tailbar
            Image {
              localFiles {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
            Icon {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    placeholder: NONE
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
        size: RF_Size
        Height
        Achaalal
        mdMn: Description_mongol {
          internal {
            content
          }
        }
        mdEn: Description_english {
          internal {
            content
          }
        }
        New
        ontsloh: Ontsloh_baraa_bolgoh
        Image {
          localFiles {
            childImageSharp {
              gatsbyImageData(
                width: 400
                layout: CONSTRAINED
                # placeholder: BLURRED
              )
            }
          }
        }
        country: Made_In {
          data {
            mn
            en
            Flag {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    layout: CONSTRAINED
                    placeholder: NONE
                  )
                }
              }
            }
          }
        }
        Brand {
          data {
            mn
            en
            Logo {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 200
                    layout: CONSTRAINED
                    placeholder: NONE
                  )
                }
              }
            }
          }
        }
      }
      id
      table
    }
  }
`

export default FloorSinglePageTemplate
